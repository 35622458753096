import React from "react"
import Layout from "../components/layout"
import Head from "../components/Head"
import Nav from "../components/Nav"
import Header from "../components/Header"
import Footer from "../components/Footer"
import profileGM from "../images/profile-image-GM2019.jpg"

export default () => (
    <Layout>
        <Head />
        <Nav />
        <Header title="About me"/>
        <div className="main">
            <img src={profileGM} alt="Working in Orlando at Automattic Grand Meetup 2019" />
            <p style={{maxWidth: "62%"}}>My name is Davor Altman and I am a Happiness Engineer working at <a href="https://automattic.com/" target="_blank" rel="noopener noreferrer">Automattic</a>. I'm building this site with Gatsby but I'm blogging <a href="https://davor.blog" target="_blank" rel="noopener noreferrer">on my site on WordPress.com</a>.</p>
        </div>
        <Footer />
    </Layout>
)